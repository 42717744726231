.vehicle-map-icon {
  background-color: transparent;
  background-image: url("../../img/markerIdle.png");
  background-size: 23px 28px;
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-top: -18px;
}

.vehicle-map-icon::before {
  content: "";
  position: absolute;
  inset: 4px;
  width: 36px;
  height: 36px;
  background-size: 15px 16px;
  background-position: 6px 4px;
  background-image: url("../../img/noLogoCar.png");
  background-repeat: no-repeat;
}
.vehicle-fleet-ee_cachet::before{
  content: "";
  position: absolute;
  inset: 4px;
  width: 36px;
  height: 36px;
  background-size: 15px 16px;
  background-position: 6.5px 4px;
  background-image: url("../../img/bolt.png");
  background-repeat: no-repeat;
}
.vehicle-fleet-ee_citybee::before {
  content: "";
  position: absolute;
  inset: 4px;
  width: 36px;
  height: 36px;
  background-size: 15px 16px;
  background-position: 6.5px 4px;
  background-image: url("../../img/cityBeeLogo.png");
  background-repeat: no-repeat;
}

.yhisteenus-logo::before {
  content: "";
  position: absolute;
  inset: 4px;
  width: 16px;
  height: 23px;
  background-size: 15px 16px;
  background-image: url("../../img/yhisteenus.png");
  background-repeat: no-repeat;
}

.parking-logo::before {
  content: "";
  position: absolute;
  inset: 4px;
  width: 16px;
  height: 23px;
  background-size: 15px 16px;
  background-image: url("../../img/parkingLogo.png");
  background-repeat: no-repeat;
}

.popupContainer .maplibregl-popup-content {

  padding: 0;

  .maplibregl-popup-close-button {
    top: 16px;
    right: 16px;
    font-size: 24px;
    line-height: 24px;
  }

}

.hide-popup {
  display: none !important;
}
